


















































import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "./../lib/util";
import VOffline from "v-offline";
@Component({
  components: { VOffline },
})
export default class Main extends Vue {
  bottomNav = "";
  path = "";
  processing = false;
  clientName = Util.clientName;
  async logout() {
    await this.$store.dispatch({
      type: "app/logout",
    });
    this.$router.push("login");
  }

  async refresh(){
    this.processing = true;
    await this.$store.dispatch({
      type: "deliveryOrder/getToday",
    });
    this.processing = false;
  }
  
  get isPageProfile(){
    return this.$route.path == '/main/profile'
  }
  get isPageTaskUncomplete(){
    return this.$route.path == '/main/task-uncompleted'
  }

  @Watch('bottomNav')
  onPropertyChanged(value: string, oldValue: string) {
    this.path = this.$route.path;
  }
}

// export default Vue.extend({

// });
